import styled from '@emotion/styled';
import type { PropsOf } from '@emotion/react';
import type { FC } from 'react';
import { Breakpoint, Color, getBrandBorderStyle } from '@pafcloud/style';
import { ButtonLink } from '../button-link';
import { Heading } from '../auto-heading';
import { Card } from '../card';

export const GuardMessageContainer = styled.section({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});

const Header = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: 74,
  padding: '8px 24px 8px 16px',
  borderBottom: `solid 1px ${Color.Surface.Base.Dimmed}`,

  '::after': {
    ...getBrandBorderStyle(),
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

const Title = styled(Heading)({
  color: Color.TextColors.HeadingText,
  margin: 0,
});

type Props = PropsOf<typeof Title>;

export const GuardTitle: FC<Props> = (props) => (
  <Header>
    <Title {...props} />
  </Header>
);

export const GuardMessageBox = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: 'min(656px, 90%)',
  margin: '32px auto',

  [Breakpoint.LaptopOrLarger]: {
    maxHeight: 838,
  },
});

export const GuardMessage = styled.div({
  padding: '8px 24px 32px 16px',
  color: Color.Surface.Base.Foreground,
  lineHeight: 1.5,
});

export const GuardButtonBar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  marginTop: 48,

  [Breakpoint.LaptopOrLarger]: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
  },
});

export const GuardMessageLink = styled(ButtonLink)({
  width: '90%',
  maxWidth: 240,
  textAlign: 'center',
});
